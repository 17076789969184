import Banner from "../../assets/images/bannerImg.png";
import BannerLogo from "../../assets/images/bannerLogo.png";
import { useState } from "react";
import axios from "axios";
import Logo from "../../assets/images/logo.png";
import PillsburyLogo from "../../assets/images/pillsbury_logo.png";
import Email from "../../assets/images/email (9).png";
import Password from "../../assets/images/password (6).png";
import Eye from "../../assets/images/eye (5).png";
import HideEye from "../../assets/images/hidden.png";
import { isEmailValid, isPasswordValid } from '../helper';
import { adminLoginService } from "../../services/userService";
import { useNavigate } from "react-router-dom";




const Index = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);


    const [emailErr, setEmailErr] = useState("");
    const [passwordErr, setPasswordErr] = useState('');
    const navigate = useNavigate();




    const validation = () => {
        let validate = true;
        var reWhiteSpace = new RegExp("\\s+");

        if (email === "") {
            setEmailErr("Email is required")
            validate = false;
        }
        else if (reWhiteSpace.test(email)) {
            setEmailErr("Email is invalid")
            validate = false;
        }

        else if (!isEmailValid(email)) {
            setEmailErr("Email is invalid")
            validate = false;
        }
        if (password === "") {
            setPasswordErr("Password is required")
            validate = false;
        }

        else if (!isPasswordValid(password)) {
            setPasswordErr("Password is invalid")
            validate = false;
        }
        return validate;

    }
    // const handleClick = (e) => {
    //     e.preventDefault();
    //     if (validation()) {
    //         let params = { email, password };
    //         adminLoginService(params).then((res) => {
    //             if(res?.data?.error?.errorCode == 6){
    //                 setEmailErr(res.data.error.responseMessage)
    //             } else if(res.data.error.errorCode == 5){
    //                 setEmailErr(res.data.error.errors[0].message)
    //             }
    //             if(res.data.error.errorCode == 7){
    //                 setPasswordErr(res.data.error.responseMessage)
    //             }
    //             localStorage.setItem("token", res.data.responseData.data.token)
    //             if(res.data.responseData.data.token)
    //             {axios.defaults.headers.common["x-access-token"] = res.data.responseData.data.token};
    //             localStorage.setItem("userName", res.data.responseData.data.name)
    //             navigate("/dashboard");
    //             window.location.reload();
    //         })
    //     }
    // }


    const handleClick = async (e) => {
        e.preventDefault();
        if (validation()) {
            const params = { email, password };
    
            try {
                const res = await adminLoginService(params);
    
                if (res?.data?.error?.errorCode === 6) {
                    setEmailErr(res.data.error.responseMessage);
                } else if (res?.data?.error?.errorCode === 5) {
                    setEmailErr(res.data.error.errors[0].message);
                }
    
                if (res?.data?.error?.errorCode === 7) {
                    setPasswordErr(res.data.error.responseMessage);
                }
    
                const token = res?.data?.responseData?.data?.token;
                const userName = res?.data?.responseData?.data?.name;
    
                if (token) {
                    localStorage.setItem("token", token);
                    axios.defaults.headers.common["x-access-token"] = token;
                    if (userName) {
                        localStorage.setItem("userName", userName);
                    }
                    navigate("/dashboard");
                    window.location.reload();
                }
    
            } catch (error) {
                console.error("Login failed:", error);
            }
        }
    };
    

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    }


    const handleEmail = (e) => {
        setEmail(e.target.value.toLowerCase())
        setEmailErr("")
        if (email == " ") {
            setEmail(e.target.value.trim)
        }
    }

    const handleKeyPress = (e) =>{
            if (e.key === 'Enter') {
                
    }
}


    return (
        <div className="row max-width align-center padding-left justify-content-center">
            <div className="col-md-7">
                <img src={Banner} alt="banner" className="left-margin top-margin " />
                <div className='banner-content'>
                    <img src={BannerLogo} alt="bnrLogo" />
                    <p className="white-text mt-2 regular-font">Lorem ipsum dolor sit amet, consectetur adipi sed do eiusm <br></br>od tempor incididunt</p>
                </div>

            </div>
            <div className="col-md-5 margin-top-mac">
                <div className="login-box">
                    <div className="login-form">
                        <img src={Logo} alt="logo" />
                        <span className="">
                            <img src={PillsburyLogo} alt="pillsbury" className="left-margin-30" />
                        </span>
                        <hr></hr>
                        <div className="mt-5">

                            <h4 className="bold-font">HELLO!</h4>
                            <label>EMAIL ADDRESS</label>
                            <input type="email" placeholder="Your email address" className="form-control mt-2 input-border padding-left" value={email} onChange={handleEmail} />

                            <img src={Email} className="symbol-icon" />
                            <div className="error-message">{emailErr ? emailErr : ""}</div>


                            <label className="mt-3">PASSWORD</label>
                            <input type={passwordShown ? "text" : "password"} placeholder="Password" className="form-control mt-2 input-border padding-left" value={password} onChange={(e) => (setPassword(e.target.value), setPasswordErr(""))} />

                            <img src={Password} className="symbol-icon" />
                            <button onClick={togglePassword} className="show-btn">
                                {passwordShown ?
                                    <img src={Eye} alt="eye" /> : <img src={HideEye} />}
                            </button>

                            <div className="error-message">{passwordErr ? passwordErr : ""}</div>


                            <button className="common-bg login-btn white-text mt-3" onClick={handleClick} onKeyPress={handleKeyPress}>LOGIN</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>






    )
}
export default Index;