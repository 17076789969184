import { useEffect, useState } from "react";
import Select from "react-select";
import Pagination from "react-js-pagination";

import { Modal, Table } from "react-bootstrap";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import {getCityService, deleteSOService, getSoListService, addSOService, getBakerService, uploadFileService, editSOService, getSODetailService} from "../../services/userService";

import CrossIcon from "../../assets/images/cross_icon.svg";
import { PageLoader } from "../svg";
import DeleteModal from "./../../Hooks/DeleteModal";
import SearchIcon from "../../assets/images/search (5).svg";
import { isEmailValid } from "../helper";


const SO = () => {
    const [list, setList] = useState("");
    const [loader, setLoader] = useState(true);

    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [name, setName] = useState('');
    const [email_address, setEmail_address] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [profile_image, setProfile_image] = useState('');
    const [bakeryIds, setBakeryIds] = useState([]);
    const [home_city, setHome_city] = useState('');
    const [asm_name, setAsm_name] = useState('');

    const [nameErr, setNameErr] = useState('');
    const [email_addressErr, setEmail_addressErr] = useState('');
    const [phone_numberErr, setPhone_numberErr] = useState('');
    const [profile_imageErr, setProfile_imageErr] = useState('');
    const [bakeryIdsErr, setBakeryIdsErr] = useState('');

    const [bakerList, setBakerList] = useState([]);
    const [bakerListPage, setBakerListPage] = useState(1);
    const [bakerListSearch, setBakerListSearch] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [resErr, setResErr] = useState('');
    const [id, setId] = useState('');




    const [city, setCity] = useState('');
    const [cityErr, setCityErr] = useState('');


    const [cityList, setCityList] = useState([]);


    const [soName, setSOName] = useState('');
    const [soNameErr, setSONameErr] = useState('');

    const [updateSO, setUpdateSO] = useState("");
    const [updateSOErr, setUpdateSOErr] = useState("");


    const [search, setSearch] = useState('');
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);

    localStorage.removeItem('page');





    const getSOList = () => {
        let query = `?limit=${limit}&page=${page}&name=${search}`
        getSoListService(query).then((res) => {
            setList(res.data.responseData.data)
            setTotalCount(res.data.responseData.count);
            setLoader(false);
        })
    }

    const getCityList = () => {
        getCityService().then((res) => {
            setCityList(res.data.responseData.result);
        })
    }

    const updateSOFunctionData = (event, key) => {
        setUpdateSO((prev) => ({ ...prev, [key]: event }))
    }

    useEffect(() => {
        getCityList();
    }, [])


    useEffect(() => {
        getSOList();
    }, [page, search])



    const deleteSO = (_id) => {
        setDeleteModal(true)
        setId(_id)
    }


    const updateSOClick = (item) => {
        setCreateModal(true);
        setId(item._id);
        let query = `?soId=${item._id}`;

        getSODetailService(query).then(res => {
            if(res.data.statusCode){
                let _data = res.data.responseData.data;

                setName(_data.name)
                setEmail_address(_data.email_address)
                setPhone_number(_data.phone_number)
                setProfile_image(_data.profile_image);
                let _bakeryData = _data.bakeryData.map(i => ({label: i.name, value: i._id}))
                setBakeryIds(_bakeryData)
                setHome_city({label: _data.home_city, value: _data.home_city})
                setAsm_name(_data.asm_name)
            }
        })

        
    }

    const handleClose = () => {
        setId('');
        setDeleteModal(false)
        setCreateModal(false)
        setSOName('')
        setSONameErr('')
        setCity('')
        setCityErr('')
        setUpdateSO('')
        setName('')
        setEmail_address('')
        setPhone_number('')
        setProfile_image('')
        setBakeryIds([])
        setHome_city('')
        setAsm_name('')
    }



    const validation = () => {
        let validate = true;
        if (name == "") {
            setNameErr("SO name is required")
            validate = false;
        }


        if (email_address === "") {
            setEmail_addressErr("Email is required")
            validate = false;
        }

        else if (!isEmailValid(email_address)) {
            setEmail_addressErr("Email is invalid")
            validate = false;
        }

        if (phone_number == "") {
            setPhone_numberErr("Phone number is required")
            validate = false;
        }else if (!/^\d{10}$/.test(phone_number)) {
            setPhone_numberErr("Phone number is invalid");
            validate = false;
        }

        if (bakeryIds.length == 0) {
            setBakeryIdsErr("Bakery is required")
            validate = false;
        }


        return validate;
    }


    const handleAddClick = async () => {
        if (validation()) {
            setBtnLoader(true);
            let params = {
                name,
                email_address,
                phone_number,
                profile_image,
                bakeryIds: bakeryIds.map(i => i.value),
                home_city: home_city.value || '',
                asm_name
            };

            if(id){
                params.userId = id
            }
    
            try {
                let service = id ? editSOService : addSOService;
                let res = await service(params);
                setBtnLoader(true);
                if(res.data.statusCode == 0){
                    setResErr(res.data.error.errors)
                }else{
                    handleClose();
                    getSOList();
                }
            } catch (error) {
                console.error('Error adding service:', error);
            }
        }
    };
    


    const handleDeleteClick = () => {
        deleteSOService({ _id: id }).then((res) => {
            handleClose();
            setDeleteModal(false)
            getSOList();
        })
    }



    const getBakerList = async () => {
        let query = {
            limit: 50,
            page: bakerListPage,
            search: bakerListSearch
        };
    
        try {
            const res = await getBakerService(query);
            if (res.data.statusCode) {
                setBakerList(res.data.responseData.result);
            }
        } catch (error) {
            console.error('Error fetching baker list:', error);
        }
    };
    


    useEffect(() => {
        getBakerList();
    }, [bakerListPage, bakerListSearch])


    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.set("file", file);
            try {
                const fileUploadResponse = await uploadFileService(formData);
                const _profile_img = fileUploadResponse.data.responseData.file_url;
                setProfile_image(_profile_img);
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };



    return (
        <>

            <div className="page_wrapper">
                    <div className="container-box">
                    <div className="top-head mt-3 mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h4 className="semibold-font">SALES OFFICER (SO)</h4>
                            <div>
                            <img src={SearchIcon} className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    className=" search box-shadow light-italic-font"
                                    value={search}
                                    onChange={(e) => (setSearch(e.target.value), setPage(1))} />
                            </div>

                            <button className="add-btn text-white box-shadow  medium-font grey-font" onClick={() => setCreateModal(true)}>Add SO</button>
                        </div>
                    </div>
                        <Table className="table box-shadow">
                            <thead>
                                <tr>
                                    <th>S. No</th>
                                    <th>SO Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>City</th>
                                    <th>ASM Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((item, index) => (
                                    <tr>
                                        <td><span>{((index + 1) + ((page - 1) * limit))}</span></td>
                                        <td><img className="table_user_img" src={item.profile_image} /> {item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone_number}</td>
                                        <td>{item.home_city}</td>
                                      
                                        <td>{item.asm_name}</td>
                                        <td>
                                            <img src={EditIcon} alt="edit" onClick={() => updateSOClick(item)} />
                                            <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => deleteSO(item._id)} />
                                        </td>
                                    </tr>
                                ))}
                                {list?.length ? "" :
                                    <tr className="text-center">
                                        <td></td>
                                        <td className="text-end no_data">
                                            <p>No Data Found</p>
                                        </td>
                                    </tr>
                                }
                            </tbody>

                        </Table>
                        {loader ? <div className='loader_data'><PageLoader /></div> : ""}

                        {list.length ? (
                            <div className="cm_pagination">
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={p => setPage(p)} />
                            </div>
                        ) : ''}
                            </div>
            </div>


            <DeleteModal
                deleteModal={deleteModal}
                title="SO"
                Icon={CrossIcon}
                handleClose={handleClose}
                handleDeleteClick={handleDeleteClick}
            ></DeleteModal>


            <Modal show={createModal} onHide={handleClose} size="lg" centered >
                <Modal.Header closeButton className="display">
                {id ? 'Edit' : 'Add'} Sales Officer (SO)
                </Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <h5 className="semibold-font mt-3"></h5>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Profile Image</label>
                                    <input
                                        type="file"
                                        className={`form-control`}
                                        value=""
                                        onChange={handleFileChange} />
                                        {profile_imageErr ? <div className="error-message">{profile_imageErr}</div> : ''}
                                    
                                </div>

                                <div className="profile_img">
                                    {profile_image ? <img src={profile_image} /> : ''}
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>SO Name</label>
                                    <input
                                        type="text"
                                        placeholder="SO Name"
                                        className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                        value={name}
                                        maxLength={50}
                                        onChange={(e) => (setName(e.target.value), setNameErr(''))} />
                                        {nameErr ? <div className="error-message">{nameErr}</div> : ''}
                                    
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        disabled={id}
                                        placeholder="Email"
                                        className={`form-control ${email_addressErr ? 'is-invalid' : ''}`}
                                        value={email_address}
                                        maxLength={50}
                                        onChange={(e) => (setEmail_address(e.target.value), setEmail_addressErr(''))} />
                                   {email_addressErr ? <div className="error-message">{email_addressErr}</div> : ''}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="phone"
                                        disabled={id}
                                        placeholder="Phone Number"
                                        className={`form-control ${phone_numberErr ? 'is-invalid' : ''}`}
                                        value={phone_number}
                                        maxLength={50}
                                        onChange={(e) => (setPhone_number(e.target.value), setPhone_numberErr(''))} />
                                    {phone_numberErr ? <div className="error-message">{phone_numberErr}</div> : ''}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>ASM Name</label>
                                    <input
                                        type="text"
                                        placeholder="ASM Name"
                                        className={`form-control`}
                                        value={asm_name}
                                        maxLength={50}
                                        onChange={e => setAsm_name(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Select City</label>
                                    <Select
                                        className={`cm_select`}
                                        placeholder="Select City"
                                        options={cityList.map(item => ({label: item.city_name, value: item.city_name}))}
                                        value={home_city}
                                        onChange={e => setHome_city(e)} />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Select Bakeries</label>

                                  


                                    <Select
                                        loadingIndicator={true}
                                        onInputChange={e => setBakerListSearch(e)}
                                        inputValue={bakerListSearch}
                                        isMulti
                                        className={`cm_select`}
                                        value={bakeryIds}
                                        placeholder="Select Bakeries"
                                        options={bakerList.map(item => ({label: item.name, value: item.id}))}
                                        onChange={e => setBakeryIds(e)} />
                                   
                                </div>
                            </div>

                            {resErr ? <h6 className="cm_err">{resErr}</h6> : ''}
                        </div>

                    

                        <button disabled={btnLoader} className="btn btn-primary px-5" onClick={handleAddClick}>ADD</button>
                    </div>

                </Modal.Body>

            </Modal>

          

        </>

    )
}


export default SO;